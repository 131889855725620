// import { useQuery, useQueryClient } from '@tanstack/react-query';

// const useSetGlobalState = (key: any) => {
//   const queryClient = useQueryClient();
//   return (state: any) => queryClient.setQueryData(key, state);
// };

// const useGlobalValue = (key: string[], initialData?: any) =>
//   useQuery(key, {
//     initialData
//   }).data;

// export { useSetGlobalState, useGlobalValue };

import { useQuery, useQueryClient } from '@tanstack/react-query';

const useSetGlobalState = (key: any) => {
  const queryClient = useQueryClient();
  return (state: any) => {
    // 로컬스토리지에 상태 저장
    if (typeof window !== 'undefined') {
      localStorage.setItem(key.join('-'), JSON.stringify(state));
    }
    return queryClient.setQueryData(key, state);
  };
};

const useGlobalValue = (key: string[], initialData?: any) => {
  return useQuery(
    key,
    () => {
      // 로컬스토리지에서 데이터 가져오기
      if (typeof window !== 'undefined') {
        const stored = localStorage.getItem(key.join('-'));
        return stored ? JSON.parse(stored) : initialData;
      }
      return initialData;
    },
    {
      initialData,
      staleTime: Infinity, // 데이터가 만료되지 않도록 설정
      cacheTime: Infinity, // 캐시가 유지되도록 설정
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  ).data;
};

export { useSetGlobalState, useGlobalValue };
