import styled from '@emotion/styled';
import {
  ILanguageTaskList,
  ITaskManager
} from '@src/common/config/ILanguageTask';
import { IMyTaskList } from '@src/common/config/IMyTask';
import { IProjectList } from '@src/common/config/IProject';
import { IWorkerPerWordAmountList } from '@src/common/config/IStatistics';
import { ITaskList } from '@src/common/config/ITask';
import { ReactNode, useMemo } from 'react';
import { IFullLookupDataList } from '../components/DataTable/DataTable';
import { cx } from '@emotion/css';
import Loading from '../components/Loading';
type dataTableCodeType = {
  code: string;
  value: any;
  width?: number;
  cssHeader?: any;
  cssBody?: any;
  renderHeader?: any;
  renderRowData?: any;
};

type DataListType =
  | Partial<IFullLookupDataList>
  | Partial<IProjectList>
  | Partial<ITaskList>
  | Partial<IMyTaskList>
  | Partial<ILanguageTaskList>
  | Partial<IWorkerPerWordAmountList>
  | Partial<ITaskManager>;

interface MainTableProps extends React.HTMLAttributes<HTMLTableElement> {
  columns: readonly dataTableCodeType[];
  dataList?: DataListType[];
  clickRowData?: (rowData: any) => void;
  clickColData?: { [k: string]: (rowData: any) => void };
  noRowsMessage?: ReactNode;
  gap?: number;
  counter?: any;
  isLoading?: boolean;
}

export default function MainTable(props: MainTableProps) {
  const {
    columns,
    gap,
    counter,
    dataList,
    noRowsMessage,
    clickColData,
    isLoading
  } = props;

  const ths = useMemo(() => {
    return columns.map(
      (column) => {
        const el =
          typeof column.renderHeader === 'function'
            ? column.renderHeader(column, counter, props)
            : column.value ?? '';
        return (
          <td key={column.code} className={cx(column.cssHeader)}>
            {el}
          </td>
        );
      },
      [columns]
    );
  }, [columns]);

  const tds = useMemo(() => {
    return Array.isArray(dataList) && dataList.length > 0 ? (
      dataList?.map((data, index) => {
        return (
          <tr key={index}>
            {columns.map((column, index) => {
              const k = column.code;
              const v = data[column.code];
              const isClickable =
                clickColData && typeof clickColData[k] === 'function';
              const onClick = () => {
                if (isClickable) {
                  clickColData[k](data);
                }
              };

              return (
                <td
                  key={index}
                  className={column.cssBody}
                  onClick={onClick}
                  style={{
                    cursor: isClickable ? 'pointer' : 'default'
                  }}
                >
                  {typeof column.renderRowData === 'function'
                    ? column.renderRowData(v, data, props)
                    : v ?? ''}
                </td>
              );
            })}
          </tr>
        );
      })
    ) : (
      <tr>
        <td
          colSpan={columns.length}
          style={{
            height: 46,
            color: '#727272'
          }}
        >
          {noRowsMessage ?? '조회된 정보가 없습니다.'}
        </td>
      </tr>
    );
  }, [dataList, columns, counter]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading color="#f9f9f9" lineColor="#d9d9d9" />
      </LoadingContainer>
    );
  }

  return (
    <SMainTable>
      <colgroup>
        {columns.map((column, i) => (
          <col key={i} className={column.code} />
        ))}
      </colgroup>
      <>
        <thead>
          <tr>{ths}</tr>
        </thead>
        <tbody>{tds}</tbody>
      </>
    </SMainTable>
  );
}

const SMainTable = styled.table`
  --border-color: #ececec;
  width: 1860px;
  background-color: #fff;
  border-collapse: collapse;
  border-radius: 4px;

  col {
    min-width: 115px;
    max-width: 160px;
  }
  col.projectTitle {
    min-width: 134px !important;
    max-width: 475px !important;
  }
  col.companyName {
    min-width: 122px !important;
    max-width: 235px !important;
  }

  td {
    white-space: pre-wrap;
    word-break: break-all;
    padding: 7px 10px;
  }

  thead td {
    height: 34px;
    text-align: center;
    vertical-align: middle;
  }

  tbody {
    tr {
      border-top: 1px solid #ececec;
      td {
        height: 36px;
        text-align: center;
        vertical-align: middle;
      }

      &:hover {
        background-color: #f8f5fc;
      }
    }
  }
`;

const LoadingContainer = styled.div`
  position: relative;
  height: 300px;
  color: #727272;
  text-align: center;
`;
