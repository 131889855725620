import styled from '@emotion/styled';
import React from 'react';

interface LoadingProps {
  color?: string;
  lineColor?: string;
}

const Loading = ({ color, lineColor }: LoadingProps) => {
  return (
    <Spinner color={color} lineColor={lineColor}>
      <span className="loader"></span>
    </Spinner>
  );
};

export default Loading;

const Spinner = styled.div<{ color?: string; lineColor?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color ?? 'rgba(0, 0, 0, 0.3)'};
  .loader {
    width: 65px;
    height: 65px;
    border: 5px solid ${({ lineColor }) => lineColor ?? '#fff'};
    border-bottom-color: #9b51e0;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
